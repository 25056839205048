<template>
  <div class="app-container">
    <div class="filter-container">
      <el-input
        v-if="['title'].includes(filterParam)"
        v-model="search"
        placeholder="Search"
        style="width: 270px"
        class="filter-item"
        clearable
        @clear="onFilter"
        @keyup.enter.native="onFilter"
      />

      <el-select
        v-if="filterParam === 'isActive'"
        v-model="filterSearch"
        style="width: 270px"
        class="filter-item"
      >
        <el-option
          v-for="item in statuses"
          :key="item.key"
          :label="item.label"
          :value="item.key"
        />
      </el-select>

      <el-select
        v-if="filterParam === 'storeUid'"
        v-model="filterSearch"
        filterable
        style="width: 270px"
        class="filter-item"
      >
        <el-option
          v-for="item in stores"
          :key="item.storeUid"
          :label="item.name"
          :value="item.storeUid"
        />
      </el-select>

      <el-select
        v-model="filterParam"
        style="width: 130px"
        class="filter-item"
      >
        <el-option
          v-for="item in filterOptions"
          :key="item.key"
          :label="item.label"
          :value="item.key"
        />
      </el-select>

      <el-button
        class="filter-item"
        type="primary"
        icon="el-icon-search"
        :loading="listLoading"
        @click="onFilter"
      />

      <el-button
        class="filter-item"
        type="default"
        icon="el-icon-refresh-right"
        :loading="listLoading"
        @click="onFilterReset"
      />

      <el-button
        v-permission="['admin']"
        class="filter-item fr"
        type="primary"
        icon="el-icon-circle-plus-outline"
        :loading="listLoading"
        @click="handleNewPublication"
      >
        New Publication
      </el-button>
    </div>

    <el-table
      ref="table"
      v-loading="listLoading"
      :data="list"
      border
      :max-height="maxHeight"
      @sort-change="handleSort"
    >
      <el-table-column
        label="Title"
      >
        <template slot-scope="{ row }">
          <span
            class="link-type"
            @click.stop="linkView('PublicationView', row.publicationUid, $event)"
          >
            {{ row.title }}
          </span>
        </template>
      </el-table-column>

      <el-table-column
        label="Store"
      >
        <template slot-scope="{ row }">
          <span
            class="link-type"
            @click.stop="linkView('StoreView', row.storeUid, $event)"
          >
            {{ row.storeName }}
          </span>
        </template>
      </el-table-column>

      <el-table-column
        label="Date Start"
        width="150"
        sortable="custom"
      >
        <template slot-scope="{ row }">
          <span>{{ row.dateStart | formatDate }}</span>
        </template>
      </el-table-column>

      <el-table-column
        label="Date End"
        width="150"
        sortable="custom"
      >
        <template slot-scope="{ row }">
          <span>{{ row.dateEnd | formatDate }}</span>
        </template>
      </el-table-column>

      <el-table-column
        label="Visible"
        width="90"
      >
        <template slot-scope="{ row }">
          <div v-if="row.status !== 'pending' && row.status !== 'processing'">
            <el-tag :type="row.isActive ? 'success' : 'danger'">
              {{ row.isActive ? 'Yes' : 'No' }}
            </el-tag>
          </div>
          <div v-else>
            {{ row.status }}
          </div>
        </template>
      </el-table-column>

      <el-table-column
        label="Created At"
        width="150"
        sortable="custom"
      >
        <template slot-scope="{ row }">
          <span>{{ row.createdAt | formatDate }}</span>
        </template>
      </el-table-column>

      <el-table-column
        label="Updated At"
        width="150"
        sortable="custom"
      >
        <template slot-scope="{ row }">
          <span>{{ row.updatedAt | formatDate }}</span>
        </template>
      </el-table-column>

      <el-table-column
        v-permission="['admin']"
        align="center"
        width="130"
      >
        <template
          slot-scope="{ row }"
        >
          <span v-if="row.status !== 'processing'">
            <el-button
              v-permission="['admin']"
              type="primary"
              size="mini"
              circle
              plain
              title="Edit"
              @click.stop="handlePublicationEdit(row.publicationUid)"
            >
              <i class="far fa-edit"></i>
            </el-button>
          </span>

          <el-button
            v-permission="['admin']"
            type="danger"
            size="mini"
            circle
            plain
            title="Delete"
            @click.stop="handlePublicationDelete(row.publicationUid)"
          >
            <i class="far fa-trash"></i>
          </el-button>
        </template>
      </el-table-column>
    </el-table>

    <el-pagination
      v-show="total > 0"
      :pager-count="5"
      class="pagination"
      background
      layout="total, prev, pager, next, sizes"
      :current-page.sync="listQuery.pageNumber"
      :page-size.sync="listQuery.pageSize"
      :total="total"
      @size-change="getList"
      @current-change="getList"
    />

    <el-dialog
      :visible.sync="dialogVisible"
      top="25px"
    >
      <div>
        <publication-details
          :is-edit-mode="isEditMode"
          :publication-uid="activePublicationUid"
          :visible.sync="dialogVisible"
          @update="getList"
        />
      </div>
    </el-dialog>
  </div>
</template>
<script>

import { listPublications, deletePublication } from '@/api/publication';
import { getAllStores } from '@/api/store';
import maxHeightMixin from '@/mixins/maxHeight';
import permission from '@/directive/permission';
import linkViewMixin from '@/mixins/linkView';
import publicationDetails from './components/publicationDetails';

export default {
  name: 'PublicationList',
  directives: {
    permission,
  },
  components: {
    publicationDetails,
  },
  mixins: [maxHeightMixin, linkViewMixin],
  data() {
    const pageSize = +localStorage.getItem('defPageSize');
    const defSortBy = 'createdAt';
    const defSortDir = 'desc';

    return {
      list: null,
      total: 0,
      defSortBy,
      defSortDir,
      listLoading: true,
      filterParam: 'storeUid',
      filterOptions: [
        { key: 'storeUid', label: 'Store' },
        { key: 'title', label: 'Title' },
      ],
      statuses: [
        { key: 'true', label: 'Yes' },
        { key: 'false', label: 'No' },
      ],
      stores: [],
      search: '',
      filterSearch: '',
      listQuery: {
        pageNumber: 1,
        pageSize: pageSize || 20,
        sortBy: defSortBy,
        sortDir: defSortDir,
      },
      dialogVisible: false,
      isEditMode: false,
      activePublicationUid: null,
    };
  },
  watch: {
    $route(to) {
      if (to.name === 'PublicationList') {
        this.getList();
      }
    },
  },
  created() {
    this.setDefaultFilter();
    this.getList();
    this.getStores();

    if (this.$route.params.id && this.$route.params.id !== ':id') {
      this.handlePublicationEdit(this.$route.params.id);
    }
  },
  activated() {
    this.setDefaultFilter();
    this.getList(true);
  },
  methods: {
    async getList(refresh = false) {
      try {
        this.listLoading = true;

        if (!refresh) {
          this.list = [];
          this.listQuery.pageNumber = 1;
        }

        const data = await listPublications(this.listQuery);
        const { items, pageInfo } = data.page;

        this.list = items;
        this.total = pageInfo.itemsAvailable;
        this.listLoading = false;

        localStorage.setItem('defPageSize', this.listQuery.pageSize);
      } catch (e) {
        this.listLoading = false;
      }
    },
    async getStores() {
      const data = await getAllStores();
      this.stores = data.stores;
    },
    handleSort({ prop, order }) {
      if (order !== null) {
        this.listQuery.sortBy = prop;
        this.listQuery.sortDir = order === 'ascending' ? 'asc' : 'desc';
      } else {
        this.listQuery.sortBy = this.defSortBy;
        this.listQuery.sortDir = this.defSortDir;
      }

      this.getList();
    },
    handleNewPublication() {
      this.activePublicationUid = null;
      this.isEditMode = false;
      this.dialogVisible = true;
    },
    handlePublicationEdit(publicationUid) {
      this.activePublicationUid = publicationUid;
      this.isEditMode = true;
      this.dialogVisible = true;
    },
    handlePublicationDelete(publicationUid) {
      this.$confirm('Are you sure you want to delete?', 'Warning', {
        confirmButtonText: 'OK',
        cancelButtonText: 'Cancel',
        type: 'warning',
      }).then(() => {
        deletePublication({ publicationUid }).then(() => {
          this.$message({
            type: 'success',
            message: 'Delete successful',
          });
          this.getList();
        });
      }).catch(() => {
        this.$message({
          type: 'info',
          message: 'Delete canceled',
        });
      });
    },
    onFilter() {
      let search;

      if (this.filterParam === 'isActive') {
        search = this.filterSearch;
      } else if (this.filterParam === 'storeUid') {
        search = this.filterSearch;
      } else {
        search = this.search.trim();
      }

      const isInput = ['title', 'isActive'].includes(this.filterParam);

      if ((isInput && search.length > 0) || (!isInput && search !== 'all')) {
        const { status } = this.$route.meta;
        this.listQuery.filter = JSON.stringify({
          [this.filterParam]: search,
          status,
        });
      } else {
        this.setDefaultFilter();
      }

      this.getList();
    },
    onFilterReset() {
      this.search = '';
      this.filterParam = 'title';

      this.setDefaultFilter();
      this.getList();
    },
    setDefaultFilter() {
      const { status } = this.$route.meta;

      this.listQuery.filter = JSON.stringify({ status });
    },
  },
};
</script>
