<template>
  <div class="flex-form">
    <el-form
      ref="publicationForm"
      v-loading="loading"
      :model="publicationForm"
      label-width="160px"
      :rules="rules"
      autocomplete="off"
    >
      <el-form-item>
        <h2 v-if="isEditMode">
          Edit Publication
        </h2>
        <h2 v-else-if="!isEditMode">
          Add Publication
        </h2>
      </el-form-item>

      <el-form-item
        v-if="isEditMode"
        label="Uid"
      >
        {{ publicationUid }}
      </el-form-item>

      <el-form-item
        v-if="isEditMode"
        label="Visible"
        prop="isActive"
      >
        <el-switch
          v-model="publicationForm.isActive"
        />
      </el-form-item>

      <el-form-item
        label="Store"
        prop="storeUid"
      >
        <el-select
          v-model="publicationForm.storeUid"
          filterable
        >
          <el-option
            v-for="item in stores"
            :key="item.storeUid"
            :label="item.name"
            :value="item.storeUid"
          />
        </el-select>
      </el-form-item>

      <el-form-item
        label="Title"
        prop="title"
      >
        <el-input
          v-model="publicationForm.title"
          resize="none"
          type="textarea"
          :autosize="{ minRows: 2, maxRows: 8}"
        />
      </el-form-item>

      <el-form-item
        label="Description"
        prop="description"
      >
        <el-input
          v-model="publicationForm.description"
          resize="none"
          type="textarea"
          :autosize="{ minRows: 2, maxRows: 8}"
        />
      </el-form-item>

      <el-form-item
        label="Meta Title"
        prop="metaTitle"
      >
        <el-input v-model="publicationForm.metaTitle" />
      </el-form-item>

      <el-form-item
        label="Meta Description"
        prop="metaDescription"
      >
        <el-input
          v-model="publicationForm.metaDescription"
          type="textarea"
          resize="none"
          :autosize="{ minRows: 2, maxRows: 8}"
        />
      </el-form-item>

      <el-form-item
        label="Canonical Link"
        prop="linkCanonical"
      >
        <el-input v-model="publicationForm.linkCanonical" />
      </el-form-item>

      <el-form-item
        label="Date Start"
        prop="dateStart"
      >
        <el-date-picker
          v-model="publicationForm.dateStart"
          type="date"
          placeholder="Date Start"
          value-format="yyyy-MM-dd"
        />
      </el-form-item>

      <el-form-item
        label="Date End"
        prop="dateEnd"
      >
        <el-date-picker
          v-model="publicationForm.dateEnd"
          type="date"
          placeholder="Date End"
          value-format="yyyy-MM-dd"
        />
      </el-form-item>

      <el-form-item
        label="Tags"
      >
        <el-tag
          v-for="tag in publicationForm.tags"
          :key="tag"
          closable
          :disable-transitions="false"
          @close="handleTagClose(tag)"
        >
          {{ tag }}
        </el-tag>
        <el-input
          v-if="tagInputVisible"
          ref="saveTagInput"
          v-model="tagInputValue"
          size="mini"
          class="input-new-tag"
          @keyup.enter.native="handleTagInputConfirm"
          @blur="handleTagInputConfirm"
        />
        <el-button
          v-else
          class="button-new-tag"
          style="margin-left: 10px;"
          size="small"
          @click="showInput"
        >
          + New Tag
        </el-button>
      </el-form-item>

      <el-form-item
        v-if="!isEditMode"
        label="File (pdf, zip)"
        prop="file"
      >
        <input
          ref="file"
          class="upload-input"
          type="file"
          name="file"
          @change="onChangeFileUpload($event)"
        />
      </el-form-item>

      <el-form-item
        v-if="isEditMode"
        label="Images"
        prop="images"
      >
        <div class="pdf-images">
          <el-image
            v-for="image in publication.images"
            :key="image.pageNumber"
            :src="`${image.baseUrl}/${image.relativePath}`"
          />
        </div>
      </el-form-item>

      <el-form-item class="flex-form__action-buttons">
        <el-button
          v-if="isEditMode && publication.status === 'pending'"
          type="success"
          :loading="saving"
          @click="onSubmitActivate"
        >
          Save & Activate
        </el-button>

        <el-button
          type="primary"
          :loading="saving"
          :disabled="submitDisabled && !isEditMode"
          @click="onSubmit"
        >
          Save
        </el-button>

        <el-button @click="onCancel">
          Cancel
        </el-button>

        <el-button
          v-if="isEditMode && publication.status === 'active'"
          type="danger"
          :loading="saving"
          @click="onSubmitArchived"
        >
          Move to archive
        </el-button>

        <el-button
          v-if="isEditMode && publication.status === 'archived'"
          type="danger"
          :loading="saving"
          @click="onSubmitActivate"
        >
          Move to active
        </el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { getPublication, updatePublication, createPublication } from '@/api/publication';
import { getAllStores } from '@/api/store';
import { cloneDeep } from 'lodash';
import { makeUpdateObj } from '@/utils';

export default {
  name: 'PublicationDetails',
  props: {
    isEditMode: {
      default: false,
      type: Boolean,
    },
    publicationUid: {
      default: null,
      type: String,
    },
    visible: {
      default: false,
      type: Boolean,
    },
  },
  data() {
    return {
      loading: false,
      storesLoading: false,
      saving: false,
      submitDisabled: true,
      publicationForm: {
        publicationUid: null,
        title: '',
        description: '',
        metaTitle: '',
        metaDescription: '',
        linkCanonical: '',
        status: '',
        tags: [],
        isActive: true,
      },
      file: '',
      publication: {},
      stores: {},
      tagInputVisible: false,
      tagInputValue: '',
      rules: {
        storeUid: [{
          required: true,
          trigger: 'blur',
          message: 'Store is required',
        }],
        publicationUid: [{
          required: true,
          trigger: 'blur',
          message: 'Publication is required',
        }],
        title: [{
          required: true,
          trigger: 'blur',
          message: 'Title is required',
        }],
      },
    };
  },
  watch: {
    publicationUid(val) {
      if (val) {
        this.getPublication(val);
      } else {
        this.resetForm();
      }
    },
  },
  created() {
    if (this.isEditMode) {
      this.getPublication(this.publicationUid);
    }

    this.getStores();
  },
  methods: {
    async getPublication(publicationUid) {
      try {
        this.loading = true;
        const data = await getPublication({ publicationUid });

        this.publication = cloneDeep(data.publication);
        this.publicationForm = data.publication;

        this.publicationForm.tags = this.publicationForm.tags || [];

        this.loading = false;
      } catch (e) {
        this.loading = false;

        this.onCancel();
      }
    },
    async getStores() {
      try {
        this.storesLoading = true;
        const data = await getAllStores();

        this.stores = data.stores;
        this.storesLoading = false;
      } catch (e) {
        this.storesLoading = false;

        this.onCancel();
      }
    },
    async onEditSubmit() {
      this.$refs.publicationForm.validate(async (valid) => {
        if (valid) {
          const updatedPublication = makeUpdateObj(this.publication, this.publicationForm);
          if (Object.keys(updatedPublication).length > 0) {
            try {
              this.saving = true;
              updatedPublication.publicationUid = this.publicationUid;

              await updatePublication(updatedPublication);

              this.$message({
                type: 'success',
                message: 'Data has been saved successfully',
              });

              await this.getPublication(this.publicationUid);
              this.saving = false;

              this.$emit('update');
            } catch (e) {
              this.saving = false;
            }
          }

          this.close();
        }
      });
    },
    async onAddSubmit() {
      this.$refs.publicationForm.validate(async (valid) => {
        if (valid) {
          try {
            this.saving = true;

            const formData = new FormData();
            formData.set('file', this.file);

            // eslint-disable-next-line no-restricted-syntax
            for (const key of Object.keys(this.publicationForm)) {
              if (this.publicationForm[key]) {
                formData.set(key, this.publicationForm[key]);
              }
            }

            await createPublication(formData);

            this.$message({
              type: 'success',
              message: 'Publication has been added successfully',
            });

            this.resetForm();
            this.saving = false;

            this.$emit('update');
            this.close();
          } catch (e) {
            this.saving = false;
          }
        }
      });
    },
    async onSubmit() {
      if (this.isEditMode) {
        await this.onEditSubmit();
      } else {
        await this.onAddSubmit();
      }
    },
    async onSubmitArchived() {
      this.publicationForm.status = 'archived';

      await this.onSubmit();
    },
    async onSubmitActivate() {
      this.publicationForm.status = 'active';

      await this.onSubmit();
    },
    resetForm() {
      this.$nextTick(() => {
        this.$refs.file.value = null;
        this.$refs.publicationForm.resetFields();
      });
    },
    onCancel() {
      this.close();
    },
    close() {
      this.$emit('update:visible', false);
    },
    onChangeFileUpload(event) {
      this.file = event.target?.files?.[0];
      this.submitDisabled = !this.file;
    },
    handleTagClose(tag) {
      this.publicationForm.tags.splice(this.publicationForm.tags.indexOf(tag), 1);
    },
    showInput() {
      this.tagInputVisible = true;
      this.$nextTick(() => {
        this.$refs.saveTagInput.$refs.input.focus();
      });
    },
    handleTagInputConfirm() {
      if (this.tagInputValue) {
        this.publicationForm.tags.push(this.tagInputValue);
      }
      this.tagInputVisible = false;
      this.tagInputValue = '';
    },
  },
};
</script>

<style>
.pdf-images {
  height: 800px;
  overflow-y: auto;
}

.upload-input {
  border: 0;
  height: 48px !important;
  padding: 0;
}
</style>
